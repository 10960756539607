'use client';

import Link from 'next/link';
import { useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import type { z } from 'zod';
import { login } from '@/actions/auth';
import { LoginSchema } from '@/schemas/auth';
import { LoaderIcon } from 'lucide-react';
import { useSearchParams } from 'next/navigation';
import { Button, Card, CardBody, CardFooter, CardHeader, Divider, Input, divider } from '@heroui/react';
import Logo from '@/components/svgs/Logo';
import { EyeSlashIcon } from '@/components/icons/EyeSlashIcon';
import { EyeIcon } from '@/components/icons/EyeIcon';
import { InfoIcon } from '@/components/icons/InfoIcon';
import AuthFormMessage from './auth-form-message';
import { useRouter } from 'next/navigation';
import SocialLogin from './social-login';
import { useSession } from "next-auth/react";

export default function LoginForm(): JSX.Element {
    const { update: updateSession } = useSession();
    const router = useRouter();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string>('');
    const [success, setSuccess] = useState<string>('');
    const [showOTPForm, setShowOTP] = useState<boolean>(false);
    const [isVisible, setIsVisible] = useState(false);
    const toggleVisibility = () => setIsVisible(!isVisible);
    const searchParams = useSearchParams();
    const callbackError = searchParams
        ? searchParams.get('error') === 'OAuthAccountNotLinked'
            ? 'Email in use with different provider'
            : undefined
        : undefined;

    const form = useForm<z.infer<typeof LoginSchema>>({
        resolver: zodResolver(LoginSchema),
        defaultValues: {
            email: '',
            password: '',
        },
    });

    const onSubmit = async (values: z.infer<typeof LoginSchema>) => {
        setIsLoading(true);
        try {
            const resp = await login(values, searchParams.get('redirectUrl') ?? undefined);

            if (!resp) {
                setError('Invalid server response');
                setSuccess('');
                form.reset();
                return;
            }

            if (resp.error) {
                setError(resp.error);
                setSuccess('');
                form.reset();
                return;
            }

            if (resp.success) {
                // Update the session client-side before navigation
                await updateSession();
                // Refresh the router to trigger rerender of server components
                router.refresh();
                // Redirect to dashboard or callback URL
                router.push(resp.success);
                return;
            }

            form.reset();
        } catch (err) {
            setError('Something went wrong');
            setSuccess('');
            form.reset();
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="flex h-screen w-screen justify-center bg-primary-100 py-14">
            <Card className="h-fit w-screen max-w-lg p-6">
                <CardHeader className="flex-col items-center">
                    <div className="aspect-auto w-52">
                        <Logo />
                    </div>
                </CardHeader>
                <CardHeader className="flex-col items-center">
                    <div className="text-xl font-bold">Welcome to the Scam.SG Community</div>
                    <div className="">Login to your account to continue</div>
                </CardHeader>
                <CardBody className="flex-col gap-4">
                    {(callbackError || error) && (
                        <div className="flex flex-row items-center py-2">
                            <InfoIcon className="fill-primary" />
                            <div className="text- text-primary">{callbackError || error}</div>
                        </div>
                    )}
                    <form onSubmit={form.handleSubmit(onSubmit)}>
                        {!showOTPForm ? (
                            <div className="space-y-4">
                                <div>
                                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900"> Email </label>
                                <Input {...form.register('email')} placeholder="abc@gmail.com" disabled={isLoading} />
                                </div>
                                <div>
                                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900"> Password </label>
                                <Input
                                    {...form.register('password')}
                                    type={isVisible ? 'text' : 'password'}
                                    placeholder=" "
                                    disabled={isLoading}
                                    endContent={
                                        <Button className="bg-transparent focus:outline-none" isIconOnly onClick={toggleVisibility}>
                                            {isVisible ? (
                                                <EyeSlashIcon className="pointer-events-none text-xl text-zinc-500" />
                                            ) : (
                                                <EyeIcon className="pointer-events-none text-xl text-zinc-500" />
                                            )}
                                        </Button>
                                    }
                                />
                                </div>
                                <div className="flex justify-end">
                                    <Link href="/reset-password" className="text-sm font-semibold text-primary hover:text-primary/80">
                                        Forgot password?
                                    </Link>
                                </div>
                            </div>
                        ) : (
                            <div className="space-y-4">
                                <div>
                                    <label htmlFor="code" className="block text-sm font-medium leading-6 text-gray-900">
                                        Code
                                    </label>
                                    <div className="mt-2 flex justify-center gap-2">
                                        {[0, 1, 2, 3, 4, 5].map((i) => (
                                            <Input
                                                key={i}
                                                {...form.register('code')}
                                                name={`digit-${i}`}
                                                maxLength={1}
                                                classNames={{
                                                    input: 'text-center',
                                                    base: 'w-10 h-10',
                                                }}
                                            />
                                        ))}
                                    </div>
                                </div>
                            </div>
                        )}

                        <Button type="submit" fullWidth size="md" radius="sm" className="mt-4 bg-primary text-xs font-semibold text-white" disabled={isLoading}>
                            {isLoading && <LoaderIcon className="mr-2 h-4 w-4 animate-spin" />}
                            {showOTPForm ? 'Validate' : 'SIGN IN'}
                        </Button>
                        {error && <AuthFormMessage type="error" message={error} title="Error" />}
                    </form>
                    {/* <div className="flex items-center gap-2">
                        <Divider className="flex-1" />
                        <span className="text-sm text-gray-500">or Sign in with</span>
                        <Divider className="flex-1" />
                    </div>
                    <SocialLogin /> */}
                </CardBody>
                <CardFooter className="flex justify-center">
                    <p className="text-center text-sm text-gray-500">
                        {!showOTPForm ? (
                            <>
                                Don&apos;t have an account?{' '}
                                <Link href="/register" className="font-semibold text-primary hover:text-primary/80">
                                    Sign up
                                </Link>
                            </>
                        ) : (
                            <>
                                Sign in now?{' '}
                                <Link href="/login" className="font-semibold text-primary hover:text-primary/80">
                                    Sign in
                                </Link>
                            </>
                        )}
                    </p>
                </CardFooter>
            </Card>
        </div>
    );
}
