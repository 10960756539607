import { Alert } from "@heroui/react";
import { AlertCircle, CheckCircle } from "lucide-react";


interface AuthFormMessageProps {
	title?: string;
	message: string;
	type: "success" | "error";
}
const AuthFormMessage = ({ message, type, title }: AuthFormMessageProps) => {
	return (
		<Alert variant="solid" title={title} description={message} classNames={{
			alertIcon: `mr-2 ${type === "success" ? "text-green-800" : "text-red-800"}`,
			base: `mb-4 ${type === "success" ? "bg-green-100 text-green-800" : "bg-red-100 text-red-800"}`}}>
		</Alert>
	);
};

export default AuthFormMessage;
