import { z } from 'zod';

export const LoginSchema = z.object({
    email: z.string().email(),
    password: z.string().min(6),
    code: z.optional(z.string()),
});

export const RegisterSchema = z
    .object({
        name: z.string().min(5),
        email: z.string().email(),
        password: z
            .string({ required_error: 'Password is required' })
            .min(1, 'Password is required')
            .min(8, 'Password must be more than 8 characters')
            .max(32, 'Password must be less than 32 characters'),
        matchPassword: z.string().min(1, 'Confirm password is required'),
    })
    .refine(
        (values) => values.password === values.matchPassword,

        {
            message: 'Passwords must match!',
            path: ['matchPassword'],
        }
    );

export const UserSettingsSchema = z
    .object({
        name: z.optional(z.string().min(5)),
        email: z.optional(z.string().email()),
        password: z.optional(z.string().min(6)),
        newPassword: z.optional(z.string().min(6)),
        isTwoFactorAuthEnabled: z.optional(z.boolean()),
    })
    .refine(
        (values) => {
            if (values.password && !values.newPassword) return false;
            return true;
        },
        {
            message: 'New password required',
            path: ['newPassword'],
        }
    )
    .refine(
        (values) => {
            if (values.newPassword && !values.password) return false;
            return true;
        },
        {
            message: 'Password required',
            path: ['password'],
        }
    );

export const ResetPasswordSchema = z.object({
    email: z.string().email(),
});

export const NewPasswordSchema = z.object({
    password: z.string().min(6),
});

export const MagicLinkLoginSchema = z.object({
    email: z.string().email(),
});
